// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("materialize-css")
require("slick-carousel")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', function() {
  var sidenav_elems = document.querySelectorAll('.sidenav');
  var sidenav_instances = M.Sidenav.init(sidenav_elems, {});

  var slick_options = {
    infinite: true,
    dots: true,
    autoplay:true,
    speed: 500,
    centerMode: true,
    centerPadding: '15%',
    responsive: [
      {
        breakpoint: 680,
        settings: {
          centerMode: false
        }
      }
    ]
  }
  $('.carousel-container > .slider').slick(slick_options);

  /*
  var carousel_elems = document.querySelectorAll('.carousel-container > .carousel');
  var carousel_instances = M.Carousel.init(carousel_elems, { fullWidth: true, autoplay: true });

  function autoplay() {
    var elems = document.querySelector('.carousel-container > .carousel');
    if ( elems ) {
      var instance = M.Carousel.getInstance(elems);
      instance.next();
      setTimeout(autoplay, 5000);
    }
  }
  setTimeout(autoplay, 5000);
  */

  var textile_carousel_elems = document.querySelectorAll('.textile-show-carousel > .carousel');
  var textile_carousel_instances = M.Carousel.init(textile_carousel_elems, { fullWidth: true, indicators: true});

  var scrollEvent = function (){
    var target = document.getElementsByClassName("scroll-fade");
    for(var i = 0; i < target.length; i++){
      var targetElement = target[i].getBoundingClientRect();
      var speed = 0.5 + i/5;
      if (speed > 2) {
        speed = 0.8
      }
      target[i].style.transition = `opacity ${speed}s ease-in`;
      var scroll = document.documentElement.scrollTop || document.body.scrollTop;
      var wh = window.innerHeight;
      if (scroll > scroll + targetElement.top - wh + 100){
        target[i].style.opacity = "1";
      }
    }

    var page_elem = document.querySelector(".scroll-next")
    if (page_elem && page_elem.className == "scroll-next") {
      var page_elem_offset = page_elem.offsetTop;
      var scroll_pos = document.documentElement.scrollTop || document.body.scrollTop;
      var wh = window.innerHeight;
      if(scroll_pos > page_elem_offset - wh ) {
        page_elem.className = "scroll-next active";
        page_elem.firstElementChild.click();
      }
    }
  }

  window.onscroll = scrollEvent;
  scrollEvent();
});

